<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <!-- <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Call Logs</h5>
            </div> -->
            <!-- <div class="card">
                <div class="p-d-flex p-ai-center p-flex-column p-flex-lg-row p-mb-1">
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="emp_name" :options="adminList" @change="getOutgoingCallLogs($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select admin"
                            :style="{ minWidth: '200px' }" />
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="card">
        <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
            <h5>Call Logs</h5>
        </div>
        <DataTable :value="outgoingCallLogs" :lazy="true" :paginator="true" :rows="30" :totalRecords="outgoingCallCount"
            :loading="loading" @page="onPageChange" class="p-datatable-users" data-key="ld1" :rowHover="true"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :style="{ 'overflow-wrap': 'break-word' }">
            <template #empty> No data found. </template>
            <template #loading> Loading data. Please wait... </template>
            <Column field="datatime" header="Date Time" headerStyle="width: 15%" bodyStyle="width: 13%">
                <template #body="{ data }">
                    <div>
                        {{ format_datetime(data.datetime) }}
                    </div>
                </template>
            </Column>
            <Column field="callernumber" header="Called Number" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                    <div class="text-capitalize">{{ data.lj8 ? data.lj8 : "N/A" }}
                    </div>
                    <div>{{ data.lj29 ? data.lj29 : "N/A" }}</div>
                </template>
            </Column>
            <Column field="callstatus" header="Call Status" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <span>{{ data.lj16 ? data.lj16 : "N/A" }}</span>
                </template>
            </Column>
            <Column field="callby" header="Call By" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                    <div>
                        {{ data.lj15 ? data.lj15 : "N/A" }}
                        <div>{{ data.lj28 ? data.lj28 : "N/A" }}</div>
                    </div>
                </template>
            </Column>
            <Column field="callduration" header="Call Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                <template #body="{ data }">
                    <span>{{ data.lj14 ? data.lj14 : "00:00:00" }}</span>
                </template>
            </Column>
            <!-- <Column field="billduration" header="Bill Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                        <template #body="{ data }">
                            <span>{{ data.lj34 ? data.lj34 : "00:00:00" }}</span>
                        </template>
                    </Column> -->
            <Column field="recording" header="Recording" headerStyle="width: 8%" bodyStyle="width: 8%">
                <template #body="{ data }">
                    <a v-if="data.lj17 != '' && data.lj17 != null" class="download-btn" target="_blank"
                        :href="'https://storage.googleapis.com/' +  this.storage_path + '/callrecording/outgoing/' + data.lj17">
                        <i class="pi pi-download" style="margin-left: 2px;"></i>
                    </a>
                    <span v-if="data.lj17 == '' || data.lj17 == null">N/A</span>
                </template>
            </Column>
        </DataTable>
        <Toast />
    </div>


</template>
<script>
import ApiService from '../../service/ApiService';
import moment from 'moment';

export default {
    data() {
        return {
            adminList: [],
            page_no: 0,
            outgoingCallCount: 0,
            loading: false,
            outgoingCallLogs: [],
            emp_name: '',
            userdatadisplayflag: false,
            admin_id: '',
            admin_list_status: false,
            itemsPerPage: 30,
            searchnamebtnactive: false,
            hidesearchname: false,
            searchfullname: '',
            searchnameloader: false,
            activate_read_key_arr: [],
            adminId: '',


        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        // let adminId = this.$store?.state?.adminId;
        this.getOutgoingCallLogs();
        // this.ApiService.getClientListOnly().then((data) => {
        //     if (data.status == 200) {
        //         this.adminList = data.data;
        //         let emp_name_id = data.data.filter(function (item) {
        //             return item.value == adminId;
        //         });
        //         if (emp_name_id.length > 0) {
        //             this.emp_name = emp_name_id[0];
        //         }
        //         this.getOutgoingCallLogs();
        //     } else {
        //         this.adminList = null;
        //     }
        // });
    },

    methods: {
        // getClientListOnly(e) {
        //     this.ApiService.getClientListOnly(e).then((data) => {
        //         if (data.status == 200) {
        //             this.adminList = data.data.records;
        //             if (data.data.records.length == 1) {
        //                 this.getOutgoingCallLogs({ value: data.data[0] });
        //                 this.emp_name = data.data[0];
        //             }
        //         } else {
        //             this.adminList = null;
        //         }
        //     });
        // },
        getOutgoingCallLogs(e) {
            this.userdatadisplayflag = true;
            this.loading = true;
            if (e) {
                this.admin_id = e.value.value;
            }
            this.activate_read_key_arr = [];
            this.ApiService.getOutgoingCallLogs({ page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.outgoingCallLogs = data.data;
                    this.outgoingCallCount = data.count;
                    this.loading = false;
                    this.admin_list_status = true;

                    this.searchnameloader = false;
                    // this.searchmobloader = false;
                    this.hidesearchname = false;
                    this.hidesearchmob = false;
                }
            });
        },
        activateReadMore(e) {
            this.activate_read_key_arr.push(e);
        },

        deactivateReadMore(e) {
            var index = this.activate_read_key_arr.indexOf(e);
            if (index !== -1) {
                this.activate_read_key_arr.splice(index, 1);
            }
        },
        onPageChange(e) {
            this.loading = true;
            if (this.admin_list_status) {
                this.page_no = e.page;
                this.getOutgoingCallLogs();
            }
        },
        searchFnameVoter(searchfullname) {
            this.hidesearchname = true;
            this.searchnameloader = true;
            this.lr14 = searchfullname;
            this.getOutgoingCallLogs({
                page_no: this.page_no,
                lr14: this.lr14,
            });
            this.searchnamebtnactive = true;
        },
        searchNameVoterStop() {
            this.searchnamebtnactive = false;
            this.searchfullname = "";
            this.getOutgoingCallLogs({
                page_no: this.page_no,
            });
            this.searchnameloader = false;
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },

        replacebrake(html) {
            const doc = html;
            return doc.replace(/(\r)*\n/g, '<br>');
        },
        decodeHtml(html) {
            return JSON.parse('"' + html + '"');
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
    },
};
</script>
<style scoped lang="scss">
.custom-form-group {
    display: flex;
    align-items: center;
}

.download-btn {
    padding: 10px;
    border-radius: 50%;
    height: 2.75rem;
    color: #ffffff;
    background: #3c6895;
    border: 0 none;
    width: 38.5px;
    display: inline-block;
}
</style>
